import { eachDayOfInterval } from "date-fns";

const generateDisabledDates = () => {
  const currentYear = new Date().getFullYear();

  // Define the allowed range
  const allowedStartDate = new Date(currentYear, 4, 1); // May 1
  const allowedEndDate = new Date(currentYear, 7, 31); // August 31

  // Generate intervals for disabled dates
  const disabledBeforeMay = eachDayOfInterval({
    start: new Date(currentYear, 0, 1), // January 1
    end: new Date(currentYear, 3, 30), // April 30
  });

  const disabledAfterAugust = eachDayOfInterval({
    start: new Date(currentYear, 8, 1), // September 1
    end: new Date(currentYear, 11, 31), // December 31
  });

  return [...disabledBeforeMay, ...disabledAfterAugust];
};

export default generateDisabledDates;
