import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//data
import navigationData from "../data/navigationData";

//icons
import { Facebook } from "@styled-icons/boxicons-logos/Facebook";

//locales
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Wrapper id="footer">
      <div className="footer__container container-center">
        <div className="footer__container__top">
          <div className="footer__container__top__left">
            <img src="/assets/big-icon.png" alt="big-logo" />
            <p className="text-sm">{t("about", { ns: "footer" })}</p>
            <p className="text-sm">{t("address", { ns: "footer" })}</p>
          </div>

          <div className="footer__container__top__right">
            <div className="footer__container__top__right__column">
              <h4 className="text-lg">{t("map", { ns: "footer" })}</h4>
              <ul>
                {navigationData.navigationWithoutCampsite?.map((navItem) => {
                  const { id, name, link } = navItem;

                  return (
                    <li key={id}>
                      <Link to={link}>{t(name, { ns: "navigation" })}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="footer__container__top__right__column">
              <h4 className="text-lg">{t("campsite", { ns: "footer" })}</h4>
              <ul>
                {navigationData.navigationCampsite?.map((navItem) => {
                  const { id, name, link } = navItem;

                  return (
                    <li key={id}>
                      <Link to={link}>{t(name, { ns: "navigation" })}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="footer__container__top__right__column">
              <h4 className="text-lg">{t("information", { ns: "footer" })}</h4>
              <ul>
                {navigationData.navigationInformation?.map((navItem) => {
                  const { id, name, link } = navItem;

                  return (
                    <li key={id}>
                      <Link to={link}>{t(name, { ns: "navigation" })}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="footer__container__top__right__column">
              <h4 className="text-lg">{t("socials", { ns: "footer" })}</h4>
              <ul>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.facebook.com/kempingsplaucaki/"
                  >
                    <Facebook className="icon" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer__container__bottom">
          <div className="footer__container__bottom__left">
            <p>{t("copyright", { ns: "footer" })} "Plaucaki", {new Date().getFullYear()}.</p>
          </div>

          <div className="footer__container__bottom__right">
            <p>
              {t("developer", { ns: "footer" })}{" "}
              <Link target="_blank" to="https://www.linkedin.com/in/ansisarthur/">
                Ansis Irbe
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  .footer__container {
    padding: 40px 20px;

    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 0 0;

      @media screen and (max-width: 650px) {
        flex-direction: column !important;
      }

      p {
        color: var(--grey);
      }

      a {
        color: var(--green);
        text-decoration: underline;
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
      gap: 48px;
      border-bottom: 1px solid var(--lightgrey);
      padding: 0 0 20px 0;

      @media screen and (max-width: 940px) {
        flex-direction: column !important;
      }

      &__right {
        display: flex;
        flex-grow: 1;
        gap: 24px;

        @media screen and (max-width: 650px) {
          flex-direction: column !important;
        }

        &__column {
          flex-grow: 1;

          .icon {
            max-width: 24px;
            min-width: 24px;
            width: 100%;
          }

          h4 {
            margin: 0 0 16px 0;
          }

          ul {
            display: flex;
            flex-direction: column;
            gap: 8px;

            li {
              a:hover {
                color: var(--green);
              }
            }
          }
        }
      }

      &__left {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media screen and (max-width: 940px) {
          flex-direction: row !important;
        }

        @media screen and (max-width: 650px) {
          flex-direction: column !important;
        }

        img {
          max-width: 120px;
          min-width: 120px;
          width: 100%;
          object-fit: contain;
        }

        p {
          max-width: 250px;
        }
      }
    }
  }
`;

export default Footer;
